.AS90title-container {
    padding: 0;
    margin: 0;
    position: relative
}  
.AS90title-titles{
    color: white;
    font-family:"Dunfont";
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    user-select: none;
    position:absolute;
}

.AS90title-inspiration{
    font-size: .9em;
    top: 50px;
    left: 18.2px;
}
.AS90title-pbonus{
    font-size: .9em;
    top: 113px;
    left: 17px;
}
.AS90title-perception{
    font-size: .9em;
    top: 175px;
    left: 18px;
}

.AS90title-str{
    font-size: .75em;
    top: 75.5px;
    left: 102px;
}
.AS90title-strsave{
    font-size: .7em;
    top: 38.5px;
    left: 186px;
    white-space: nowrap;
}
.AS90title-athletics{
    font-size: .7em;
    top: 46.5px;
    left: 186px;
}

.AS90title-dex{
    font-size: .75em;
    top: 75.5px;
    left: 272px;
}
.AS90title-dexsave{
    font-size: .7em;
    top: 38.5px;
    left: 360px;
    white-space: nowrap;
}
.AS90title-acrobatics{
    font-size: .7em;
    top: 46.5px;
    left: 360px;
}
.AS90title-sofhand{
    font-size: .7em;
    top: 54.5px;
    left: 360px;
    white-space: nowrap;
}
.AS90title-stealth{
    font-size: .7em;
    top: 62.3px;
    left: 360px;
}
.AS90title-rope{
    font-size: .7em;
    top: 70.3px;
    left: 360px;
    white-space: nowrap;
}

.AS90title-con{
    font-size: .75em;
    top: 75.5px;
    left: 437px;
}
.AS90title-consave{
    font-size: .7em;
    top: 38.7px;
    left: 528px;
    white-space: nowrap;
}
.AS90title-brewing{
    font-size: .7em;
    top: 46.5px;
    left: 528px;
}

.AS90title-int{
    font-size: .75em;
    top: 152px;
    left: 97px;
}
.AS90title-intsave{
    font-size: .7em;
    top: 115.5px;
    left: 186px;
    white-space: nowrap;
}
.AS90title-arcana{
    font-size: .7em;
    top: 123.8px;
    left: 186px;
}
.AS90title-history{
    font-size: .7em;
    top: 131.6px;
    left: 186px;
}
.AS90title-invest{
    font-size: .7em;
    top: 139.5px;
    left: 186px;
}
.AS90title-nature{
    font-size: .7em;
    top: 147.5px;
    left: 186px;
}
.AS90title-religion{
    font-size: .7em;
    top: 155.3px;;
    left: 186px;
}
.AS90title-appraisal{
    font-size: .7em;
    top: 162.8px;
    left: 186px;
}

.AS90title-wis{
    font-size: .75em;
    top: 152px;
    left: 280px;
}
.AS90title-wissave{
    font-size: .7em;
    top: 115.5px;
    left: 360px;
    white-space: nowrap;
}
.AS90title-ahand{
    font-size: .7em;
    top: 123.8px;
    left: 360px;
    white-space: nowrap;
}
.AS90title-insight{
    font-size: .7em;
    top: 131.6px;
    left: 360px;
    white-space: nowrap;
}
.AS90title-medicine{
    font-size: .7em;
    top: 139.5px;
    left: 360px;
}
.AS90title-percept{
    font-size: .7em;
    top: 147.5px;
    left: 360px;
}
.AS90title-survival{
    font-size: .7em;
    top: 155.3px;
    left: 360px;
}

.AS90title-cha{
    font-size: .75em;
    top: 152px;
    left: 441px;
}
.AS90title-chasave{
    font-size: .7em;
    top: 115.5px;
    left: 528px;
    white-space: nowrap;
}
.AS90title-deception{
    font-size: .7em;
    top: 123.8px;
    left: 528px;
}
.AS90title-intimidate{
    font-size: .7em;
    top: 131.6px;
    left: 528px;
}
.AS90title-performance{
    font-size: .7em;
    top: 139.5px;
    left: 528px;
}
.AS90title-persuasion{
    font-size: .7em;
    top: 147.5px;
    left: 528px;
}
