.ASdata-container {
    padding: 0;
    margin: 0;
    padding-left: 3px;
    position: relative;
}  
.ASdata-font{
    color: white;
    font-family:"Dunfont";
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    user-select: none;
    position:absolute;
}

.ASdata-insperationsvg{
    width: 28px;
    position: absolute;
    top:  14px;
    left: 21.2px;
}
.ASdata-Pbonus{
    font-size: 2em;
    top: 12px;
    left: 90px;
}
.ASdata-percept{
    font-size: 2em;
    top: 12px;
    left: 156px;
}

.ASdata-str{
    font-size: 2em;
    top: 90px;
    left: 38px;
}
.ASdata-strmod{
    top: 75px;
    left: 40px;
}

.ASdata-dex{
    font-size: 2em;
    top: 152px;
    left: 38px;
}
.ASdata-dexmod{
    top: 138px;
    left: 40px;
}

.ASdata-con{
    font-size: 2em;
    top: 217px;
    left: 37px;
}
.ASdata-conmod{
    top: 201.5px;
    left: 40px;
}

.ASdata-int{
    font-size: 2em;
    top: 281px;
    left: 39px;
}
.ASdata-intmod{
    top: 266px;
    left: 40px;
}

.ASdata-wis{
    font-size: 2em;
    top: 347px;
    left: 36.5px;
}
.ASdata-wismod{
    top: 330.5px;
    left: 42.5px;
}

.ASdata-cha{
    font-size: 2em;
    top: 411px;
    left: 37px;
}
.ASdata-chamod{
    top: 394px;
    left: 40px;
}

.ASdata-strsave{
    font-size: .65em;
    top: 79.5px;
    left: 105px;
}
.ASdata-athletics{
    font-size: .68em;
    top: 87.4px;
    left: 105px; 
}

.ASdata-dexsave{
    font-size: .68em;
    top: 142px;
    left: 105px;
}
.ASdata-acrobatics{
    font-size: .68em;
    top: 150px;
    left: 105px;
}
.ASdata-sofhand{
    font-size: .68em;
    top: 157.8px;
    left: 105px;
}
.ASdata-stealth{
    font-size: .68em;
    top: 166px;
    left: 105px;
}
.ASdata-userope{
    font-size: .68em;
    top: 174px;
    left: 105px;
}

.ASdata-consave{
    font-size: .68em;
    top: 205.6px;
    left: 105px;
}
.ASdata-brewing{
    font-size: .68em;
    top: 213.7px;
    left: 105px;
}

.ASdata-intsave{
    font-size: .68em;
    top: 268px;
    left: 105px;
}
.ASdata-arcana{
    font-size: .68em;
    top: 275.5px;
    left: 105px;
}
.ASdata-lore{
    font-size: .68em;
    top: 283px;
    left: 105px;
}
.ASdata-investigate{
    font-size: .68em;
    top: 290.3px;
    left: 105px;
}
.ASdata-nature{
    font-size: .68em;
    top: 298px;
    left: 105px;
}
.ASdata-religion{
    font-size: .68em;
    top: 305.4px;
    left: 105px;
}
.ASdata-appraisal{
    font-size: .68em;
    top: 312.8px;
    left: 105px;
}

.ASdata-wissave{
    font-size: .68em;
    top: 334.6px;
    left: 105px;
}
.ASdata-animialhand{
    font-size: .68em;
    top: 342.6px;
    left: 105px;
}
.ASdata-insight{
    font-size: .68em;
    top: 350.5px;
    left: 105px;
}
.ASdata-medicine{
    font-size: .68em;
    top: 358.5px;
    left: 105px;
}
.ASdata-perception{
    font-size: .68em;
    top: 366.6px;
    left: 105px;
}
.ASdata-survival{
    font-size: .68em;
    top: 374.4px;
    left: 105px;
}

.ASdata-chasave{
    font-size: .68em;
    top: 397.8px;
    left: 105px;
}
.ASdata-deception{
    font-size: .68em;
    top: 406px;
    left: 105px;
}
.ASdata-intimidation{
    font-size: .68em;
    top: 413.7px;
    left: 105px;
}
.ASdata-performance{
    font-size: .68em;
    top: 421.8px;
    left: 105px;
}
.ASdata-persuasion{
    font-size: .68em;
    top: 430px;
    left: 105px;
}

.ASdata-profdot{
    height: 5.4px;
    width: 5.4px;
    border: 0;
    border-radius: 50%;
    outline: none;
    background-color: white;

    position: absolute;
    padding: 0;
    margin: 0;
}

.ASdata-nonat{
    visibility: hidden;
}
.ASdata-profat{
    left: 85.5px;
}
.ASdata-expertat{
    left: 94.5px;
}

.ASdata-dot-athletics{
    top: 90.5px;
}
.ASdata-dot-acrobatics{
    top: 153px;
}
.ASdata-dot-sofhand{
    top: 160.9px;
}
.ASdata-dot-stealth{
    top: 168.8px;
}
.ASdata-dot-userope{
    top: 176.7px;
}
.ASdata-dot-brewing{
    top: 216px;
}
.ASdata-dot-arcana{
    top: 278px;
}
.ASdata-dot-lore{
    top: 285.2px;
}
.ASdata-dot-investigate{
    top: 293px;
}
.ASdata-dot-nature{
    top: 300.2px;
}
.ASdata-dot-religion{
    top: 308.2px;
}
.ASdata-dot-appraisal{
    top: 315.2px;
}
.ASdata-dot-animialhand{
    top: 344.5px;
}
.ASdata-dot-insight{
    top: 352.3px;
}
.ASdata-dot-medicine{
    top: 360.3px;
}
.ASdata-dot-perception{
    top: 368.2px;
}
.ASdata-dot-survival{
    top: 376.2px;
}
.ASdata-dot-deception{
    top: 408px;
}
.ASdata-dot-intimidation{
    top: 416px;
}
.ASdata-dot-performance{
    top: 424px;
}
.ASdata-dot-persuasion{
    top: 431.7px;
}

.ASdata-profsquare{
    height: 5px;
    width: 5px;
    border: 0;
    transform: rotate(-45deg);
    border-radius: 1px;
    outline: none;
    background-color: white;

    position: absolute;
    padding: 0;
    margin: 0;

    left: 89.8px
}
.ASdata-profsquare-strsave{
    top: 82.7px
}
.ASdata-profsquare-dexsave{
    top: 145px
}
.ASdata-profsquare-consave{
    top: 208.8px
}
.ASdata-profsquare-intsave{
    top: 271px
}
.ASdata-profsquare-wissave{
    top: 337px
}
.ASdata-profsquare-chasave{
    top: 400.5px
}


