.infosquaredata-features-name{
    color: var(--font-color-title);
    padding-right: 10px;
    display: inline-block;

    width: 100px;
    white-space: nowrap;
}
.infosquaredata-actions-desc{
    padding-right: 10px;
    display: inline-block;
    width: 250px;
    white-space: nowrap;
}
.infosquaredata-features-paragraph{
    padding: 0;
    margin: 0;
}