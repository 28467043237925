:root {
    --background-color: #595959;
    --font-color-title: hsl(0, 0%, 100%);
    --font-color-subtitle: hsl(0, 0%, 70%);
    --font-color-data: hsl(0, 0%, 100%);

    margin: 0;
    padding: 0;
    scrollbar-width: none;

    background-color: var(--background-color);
    zoom:.95;

    
}

:root::-webkit-scrollbar {
    display: none;
}

body {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: "Dunfont";
    src: url("./fonts/DUNG.TTF") format("truetype");
    font-weight: normal;
}

.app-container{
    padding-top: 2vh;
    margin: 0;

    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.main-container{
    display: grid;
    grid-column-gap: 7px;
    grid-row-gap: 2px;
    grid-auto-flow: row dense;
    
    grid-template-columns: 200px 200px 200px 200px 200px;
    grid-template-rows: 92px 92px 92px 92px 92px;
}
.ASdata-container{
    grid-column: 1;
    grid-row: 1/5;

    zoom: 1.012;
    justify-self: center;
}
.PSdata-container{
    grid-column: 2;
    grid-row: 2/4;

    justify-self: center;
}
.infodata-container{
    grid-column: 2/5;
    grid-row-start: 1;

    zoom: .95;
    justify-self: center;
}

.actionsdata-container{
    justify-self: center;
}

.profsdata-container{
    justify-self: center;
    grid-column: 2;
    grid-row: 4;
}

.classfeatdata-container{
    justify-self: center;
    grid-column: 5;

    height: 465px;
}

.mondice-container{
    display : block;
    padding-top: 4px;

    display: flex;
    justify-content: space-around;
}
.infosquare-container{
    grid-row: 2;
}
.AS90data-container{
    display: none;
}

@media screen and (max-width: 1000px) and (min-width: 700px){
    .app-container{    
        margin: auto;
    }
    .main-container{
        display: grid;
        grid-column-gap: 7px;
        grid-row-gap: 3px;
        grid-auto-flow: row dense;
        
        grid-template-columns: 200px 200px 200px 200px;
        grid-template-rows: repeat(92px);
    }
    .infodata-container{
        zoom: .95;
        justify-self: center;
        grid-row-start: 1;
        grid-column: 2/5;
    }
    .ASdata-container{
        zoom: 1.012;
        justify-self: center;

        grid-row: 1;
        grid-column: 1;
    }
    .PSdata-container{
        justify-self: center;
        grid-row: 2;
        grid-column: 2;
    }

    .actionsdata-container{
        justify-self: center;
        grid-row: auto;
        grid-column: auto;
    }
    .profsdata-container{
        justify-self: center;
        grid-row: 4;
        grid-column: 2;
    }
    .classfeatdata-container{
        display: none;
    }
    .infosquare-container{
        grid-row: 2;
        grid-column: 3;       
    }
    .mondice-container{
        display : block;
        padding-top: 4px;
    
        display: flex;
        justify-content: space-around;
    }
    .AS90data-container{
        display: none;
    }

}

@media screen and (max-width: 700px) {
    :root{
        zoom: 1.1;
    }
    .app-container{    
        left: auto;
        transform: none;
        padding-left: 2vw;
    }
    .main-container{
        display: grid;
        grid-column-gap: 7px;
        grid-row-gap: 3px;
        grid-auto-flow: row dense;
        
        grid-template-columns: 200px 200px 200px;
        grid-template-rows: repeat(92px);
    }
    .infodata-container{
        zoom: .95;
        justify-self: center;
        grid-row-start: 1;
        grid-column: 1/4;
    }
    .ASdata-container{
        display: none;
    }
    .PSdata-container{
        justify-self: center;
        grid-row: 2;
        grid-column: 1;
    }

    .actionsdata-container{
        justify-self: center;
        grid-row: auto;
        grid-column: auto;
    }
    .profsdata-container{
        justify-self: center;
        grid-row: 4;
        grid-column: 1;
    }
    .classfeatdata-container{
        display: none;
    }
    .infosquare-container{
        grid-row: 2;
        grid-column: 2;       
    }
    .AS90data-container{
        display: inline-block;
        zoom: 1.012;
        grid-row: 6;
        grid-column: 1;  
    }
    .mondice-container{
        display : grid;
        padding-top: 4px;

        align-content:space-around;

    }
    .Moneydata-container{
        width: fit-content;
        grid-row: 1;
    }
    .settings-container{
        width: fit-content;
        grid-row: 1;
    }
    .Dicedata-container{
        width: fit-content;
    }
}