.infosquare-container{
    padding: 0;
    margin: 0;
    position: relative;
}
.infosquaretitle-titles{
    color: var(--font-color-subtitle);
    font-family:"Dunfont";

    letter-spacing: 0;
    margin: 0;
    padding: 0;

    position:absolute;
    font-weight: normal;
    font-size: 1.5em;
    top: 3.5px;
    height: 22px;

    user-select: none;
    cursor:pointer;
}

.infosquaretitle-titles-selected{
    color: var(--font-color-title);
}


.infosquaretitle-actions{
    left: 10px;
}
.infosquaretitle-spells{
    left: 70px;
}
.infosquaretitle-features{
    left: 125px;
}
.infosquaretitle-invent{
    left: 195px;
}
.infosquaretitle-notes{
    left: 270px;
}


.infosquare-hidden {
    height:0;
    width:0;
    visibility:hidden;
    display: none;
}    
input:checked + label {
    color:#e0e0e0;
}

.infosquaredata-container{
    position: absolute;
    width: 385px;
    height: 328.8px;

    top: 37px;
    left: 15px;

    overflow-y:scroll;
    scrollbar-width: none;
}
.infosquaredata-container::-webkit-scrollbar {
    display: none;
}

.infosquaredata-text{
    color: var(--font-color-subtitle);
    font-family:"Dunfont";

    letter-spacing: .03em;
    margin: 0;
    padding: 0;

    font-weight: normal;
    font-size: 1em;

    height: 1em;
}

.infosquaredata-title{
    color: var(--font-color-subtitle);
    font-family:"Dunfont";

    letter-spacing: 0;
    margin: 0;
    padding: 0;

    font-weight: normal;
    font-size: 1.2em;

    text-decoration: underline;
    cursor: pointer;
    display: block;
}

.infosquaredata-all{
    height: fit-content;
    margin-bottom: .5em;
}

.infosquaredata-title-textbox{
    display: block;
    background-color: transparent;
    border: 1px solid var(--font-color-subtitle);
    border-radius: 4px;
    color: var(--font-color-subtitle);

    height: 295px;
    width: 375px;
    margin-left: 1px;

    overflow-y:scroll;
    scrollbar-width: none;
}
.infosquaredata-title-textbox::-webkit-scrollbar {
    display: none;
}  

.infosquaredata-title-padding{
    height: 10px;
}