.infosquaredata-magic-spellname{
    color: var(--font-color-title);
    padding-right: 10px;
    margin-right:5px;
    display: inline-block;

    width: 91px;
    white-space: nowrap;
    overflow: hidden;
}
.infosquaredata-magic-spellrange{
    padding-right: 10px;
    display: inline-block;

    width: 35px;
    white-space: nowrap;
    overflow: hidden;
}
.infosquaredata-magic-spelldur{
    padding-right: 10px;
    display: inline-block;

    width: 35px;
    white-space: nowrap;
    overflow: hidden;
}
.infosquaredata-magic-spelleffect{
    padding-right: 10px;
    display: inline-block;

    width: 179px;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
}
.infosquaredata-magic-spelleffect::-webkit-scrollbar {
    display: none;
}
.infosquaredata-magic-link{
    color: inherit; 
    text-decoration: inherit;
}