.AStitle-container {
    padding: 0;
    margin: 0;
    position: relative
}  
.AStitle-titles{
    color: white;
    font-family:"Dunfont";
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    user-select: none;
    position:absolute;
}

.AStitle-inspiration{
    font-size: .9em;
    top: 50px;
    left: 8.2px;
}
.AStitle-pbonus{
    font-size: .9em;
    top: 50px;
    left: 75.5px;
}
.AStitle-perception{
    font-size: .9em;
    top: 50px;
    left: 143px;
}

.AStitle-str{
    font-size: .75em;
    top: 115.5px;
    left: 30px;
}
.AStitle-strsave{
    font-size: .7em;
    top: 79.3px;
    left: 118px;
}
.AStitle-athletics{
    font-size: .7em;
    top: 87.3px;
    left: 118px;
}

.AStitle-dex{
    font-size: .75em;
    top: 178px;
    left: 28px;
}
.AStitle-dexsave{
    font-size: .7em;
    top: 142.2px;
    left: 118px;
}
.AStitle-acrobatics{
    font-size: .7em;
    top: 150.2px;
    left: 118px;
}
.AStitle-sofhand{
    font-size: .7em;
    top: 158.2px;
    left: 118px;
}
.AStitle-stealth{
    font-size: .7em;
    top: 166.2px;
    left: 118px;
}
.AStitle-rope{
    font-size: .7em;
    top: 174.2px;
    left: 118px;
}

.AStitle-con{
    font-size: .75em;
    top: 243px;
    left: 21px;
}
.AStitle-consave{
    font-size: .7em;
    top: 205.8px;
    left: 118px;
}
.AStitle-brewing{
    font-size: .7em;
    top: 214px;
    left: 118px;
}

.AStitle-int{
    font-size: .75em;
    top: 306px;
    left: 24px;
}
.AStitle-intsave{
    font-size: .7em;
    top: 269px;
    left: 118px;
}
.AStitle-arcana{
    font-size: .7em;
    top: 276px;
    left: 118px;
}
.AStitle-history{
    font-size: .7em;
    top: 283.5px;
    left: 118px;
}
.AStitle-invest{
    font-size: .7em;
    top: 291px;
    left: 118px;
}
.AStitle-nature{
    font-size: .7em;
    top: 299px;
    left: 118px;
}
.AStitle-religion{
    font-size: .7em;
    top: 306.5px;
    left: 118px;
}
.AStitle-appraisal{
    font-size: .7em;
    top: 314px;
    left: 118px;
}

.AStitle-wis{
    font-size: .75em;
    top: 370.3px;
    left: 31px;
}
.AStitle-wissave{
    font-size: .7em;
    top: 335.5px;
    left: 118px;
}
.AStitle-ahand{
    font-size: .7em;
    top: 343.2px;
    left: 118px;
}
.AStitle-insight{
    font-size: .7em;
    top: 351.2px;
    left: 118px;
}
.AStitle-medicine{
    font-size: .7em;
    top: 359.4px;
    left: 118px;
}
.AStitle-percept{
    font-size: .7em;
    top: 367.2px;
    left: 118px;
}
.AStitle-survival{
    font-size: .7em;
    top: 375.1px;
    left: 118px;
}

.AStitle-cha{
    font-size: .75em;
    top: 435.3px;
    left: 28px;
}
.AStitle-chasave{
    font-size: .7em;
    top: 399.2px;
    left: 118px;
}
.AStitle-deception{
    font-size: .7em;
    top: 407px;
    left: 118px;
}
.AStitle-intimidate{
    font-size: .7em;
    top: 415px;
    left: 118px;
}
.AStitle-performance{
    font-size: .7em;
    top: 423px;
    left: 118px;
}
.AStitle-persuasion{
    font-size: .7em;
    top: 431px;
    left: 118px;
}
