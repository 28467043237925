.Moneydata-container {
    padding: 0;
    margin: 0;
    position: relative;
}  
.Moneydata-text{
    color: white;
    font-family:"Dunfont";
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    user-select: none;
    position:absolute;

    font-size: 2em;
    top: 6px;

    width: 50px;
    background-color: transparent;
    border: 0;
    text-align: center;
    outline: none;
}

.Moneydata-cp{
    left: 38px;
}
.Moneydata-sp{
    left: 133px;
}
.Moneydata-gp{
    left: 229px;
}
.Moneydata-pp{
    left: 326px;
}