.PSdata-container {
    padding: 0;
    margin: 0;
    position: relative;
}  
.PSdata-text{
    color: white;
    font-family:"Dunfont";
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    user-select: none;
    position:absolute;
}

.PSdata-ac{
    font-size: 2.2em;
    top: 23px;
    left: 26px;
}
.PSdata-initiative{
    font-size: 2.2em;
    top: 23px;
    left: 87px;
}
.PSdata-speed{
    font-size: 2.2em;
    top: 23px;
    left: 155px;
}
.PSdata-hitdice{
    font-size: .95em;
    top: 130px;
    left: 32px;
}

.PSdata-maxHP{
    font-size: 1.2em;
    top: 128px;
    left: 90px;
}
.PSdata-tempHP{
    font-size: 1.2em;
    top: 94px;
    left: 85px;

    width: 23px;
    background-color: transparent;
    border: 0;
    text-align: center;
    outline: none;
}
.PSdata-currentHP{
    font-size: 2.2em;
    top: 104px;
    left: 130px;

    width: 40px;
    background-color: transparent;
    border: 0;
    text-align: center;
    outline: none;
}
  
input[type=checkbox] {
    cursor: pointer;
    visibility: hidden;
} 
input[type=checkbox]:after {
    content: "";
    display: inline-block;

    visibility: visible;
    border-radius: 50%;
    width: 7.5px;
    height: 7.5px;
}
input[type=checkbox]:checked:after {
    background-color: white;
}

#chp1{
    position: absolute;
    top: 94.5px;
    left: 30.2px;
}
#chp2{
    position: absolute;
    top: 94.5px;
    left: 42.2px;
}
#chp3{
    position: absolute;
    top: 94.5px;
    left: 54.2px;
}

#chf1{
    position: absolute;
    top: 104.5px;
    left: 30.3px;
}
#chf2{
    position: absolute;
    top: 104.5px;
    left: 42.2px;
}
#chf3{
    position: absolute;
    top: 104.5px;
    left: 54.2px;
}
