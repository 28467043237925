.AS90data-container {
    padding: 0;
    margin: 0;
    position: relative;
}  
.AS90data-font{
    color: white;
    font-family:"Dunfont";
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    user-select: none;
    position:absolute;
}

.AS90data-insperationsvg{
    width: 28px;
    position: absolute;
    top:  15.5px;
    left: 28px;
}
.AS90data-Pbonus{
    font-size: 2em;
    top: 77px;
    left: 31px;
}
.AS90data-percept{
    font-size: 2em;
    top: 140px;
    left: 31px;
}

.AS90data-str{
    font-size: 2em;
    top: 50px;
    left: 109px;
}
.AS90data-strmod{
    top: 36px;
    left: 110.5px;
}

.AS90data-dex{
    font-size: 2em;
    top: 50px;
    left: 279px;
}
.AS90data-dexmod{
    top: 36px;
    left: 281px;
}

.AS90data-con{
    font-size: 2em;
    top: 50px;
    left: 449px;
}
.AS90data-conmod{
    top: 36px;
    left: 452px;
}

.AS90data-int{
    font-size: 2em;
    top: 126px;
    left: 109px;
}
.AS90data-intmod{
    top: 113px;
    left: 112px;
}

.AS90data-wis{
    font-size: 2em;
    top: 126px;
    left: 280px;
}
.AS90data-wismod{
    top: 113.5px;
    left: 288px;
}

.AS90data-cha{
    font-size: 2em;
    top: 126px;
    left: 449px;
}
.AS90data-chamod{
    top: 112.5px;
    left: 452px;
}

.AS90data-strsave{
    font-size: .66em;
    top: 39px;
    left: 174px;
}
.AS90data-athletics{
    font-size: .66em;
    top: 47px;
    left: 174px;
}

.AS90data-dexsave{
    font-size: .66em;
    top: 39px;
    left: 348px;
}
.AS90data-acrobatics{
    font-size: .66em;
    top: 47px;
    left: 348px;
}
.AS90data-sofhand{
    font-size: .66em;
    top: 55px;
    left: 348px;
}
.AS90data-stealth{
    font-size: .66em;
    top: 64px;
    left: 348px;
}
.AS90data-userope{
    font-size: .66em;
    top: 72px;
    left: 348px;
}

.AS90data-consave{
    font-size: .66em;
    top: 39px;
    left: 515px;
}
.AS90data-brewing{
    font-size: .66em;
    top: 47.2px;
    left: 515px;
}

.AS90data-intsave{
    font-size: .66em;
    top: 117px;
    left: 174px;
}
.AS90data-arcana{
    font-size: .66em;
    top: 126px;
    left: 174px;
}
.AS90data-lore{
    font-size: .66em;
    top: 133px;
    left: 174px;
}
.AS90data-investigate{
    font-size: .66em;
    top: 141px;
    left: 174px;
}
.AS90data-nature{
    font-size: .66em;
    top: 149px;
    left: 174px;
}
.AS90data-religion{
    font-size: .66em;
    top: 156px;
    left: 174px;
}
.AS90data-appraisal{
    font-size: .66em;
    top: 164px;
    left: 174px;
}

.AS90data-wissave{
    font-size: .66em;
    top: 117px;
    left: 348px;
}
.AS90data-animialhand{
    font-size: .66em;
    top: 125px;
    left: 348px;
}
.AS90data-insight{
    font-size: .66em;
    top: 133px;
    left: 348px;
}
.AS90data-medicine{
    font-size: .66em;
    top: 141px;
    left: 348px;
}
.AS90data-perception{
    font-size: .66em;
    top: 149px;
    left: 348px;
}
.AS90data-survival{
    font-size: .66em;
    top: 156px;
    left: 348px;
}

.AS90data-chasave{
    font-size: .66em;
    top: 116.1px;
    left: 515px;
}
.AS90data-deception{
    font-size: .66em;
    top: 124.2px;
    left: 515px;
}
.AS90data-intimidation{
    font-size: .66em;
    top: 132px;
    left: 515px;
}
.AS90data-performance{
    font-size: .66em;
    top: 140px;
    left: 515px;
}
.AS90data-persuasion{
    font-size: .66em;
    top: 148px;
    left: 515px;
}

.AS90data-profdot{
    height: 5.4px;
    width: 5.4px;
    border: 0;
    border-radius: 50%;
    outline: none;
    background-color: white;

    position: absolute;
    padding: 0;
    margin: 0;
}

.AS90data-nonat{
    visibility: hidden;
}
.AS90data-profat-r1{
    left: 153.7px;
}
.AS90data-profat-r2{
    left: 329px;
}
.AS90data-profat-r3{
    left: 495.7px;
}
.AS90data-expertat-r1{
    left: 162.9px;
}
.AS90data-expertat-r2{
    left: 337.8px;
}
.AS90data-expertat-r3{
    left: 504.8px;
}

.AS90data-dot-athletics{
    top: 49.8px;;
}
.AS90data-dot-acrobatics{
    top: 49.8px;
}
.AS90data-dot-sofhand{
    top: 57.9px;
}
.AS90data-dot-stealth{
    top: 65.8px;
}
.AS90data-dot-userope{
    top: 73.7px;
}
.AS90data-dot-brewing{
    top: 49.8px;;
}
.AS90data-dot-arcana{
    top: 127px;
}
.AS90data-dot-lore{
    top: 135px;
}
.AS90data-dot-investigate{
    top: 142.6px;
}
.AS90data-dot-nature{
    top: 150.5px;
}
.AS90data-dot-religion{
    top: 158.5px;
}
.AS90data-dot-appraisal{
    top: 166.2px;
}
.AS90data-dot-animialhand{
    top: 127px;
}
.AS90data-dot-insight{
    top: 134.6px;
}
.AS90data-dot-medicine{
    top: 142.7px;
}
.AS90data-dot-perception{
    top: 150.7px;
}
.AS90data-dot-survival{
    top: 158.8px;
}
.AS90data-dot-deception{
    top: 127px;
}
.AS90data-dot-intimidation{
    top: 134.9px;
}
.AS90data-dot-performance{
    top: 142.9px;
}
.AS90data-dot-persuasion{
    top: 150.7px;
}

.AS90data-profsquare{
    height: 5px;
    width: 5px;
    border: 0;
    transform: rotate(-45deg);
    border-radius: 1px;
    outline: none;
    background-color: white;

    position: absolute;
    padding: 0;
    margin: 0;
}
.AS90data-profsquare-strsave{
    top: 42px;
    left: 158.3px;
}
.AS90data-profsquare-dexsave{
    top: 42px;
    left: 333.3px
}
.AS90data-profsquare-consave{
    top: 42px;
    left: 500.3px
}
.AS90data-profsquare-intsave{
    top: 119.5px;
    left: 158.3px;
}
.AS90data-profsquare-wissave{
    top: 119.5px;
    left: 333.3px
}
.AS90data-profsquare-chasave{
    top: 119.5px;
    left: 500.3px
}


