.profsdata-container {
    padding: 0;
    margin: 0;
    position: relative;
}  
.profsdata-text{
    color: white;
    font-family:"Dunfont";
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    user-select: none;
    position:absolute;

    line-height: .8;
    overflow: auto;
    scrollbar-width: none;
}

.profsdata-text::-webkit-scrollbar {
    display: none;
}

.profsdata-armor{
    font-size: .8em;
    top: 18px;
    left: 10px;

    width: 183px;
    height: 25.5px;
}
.profsdata-weapons{
    font-size: .8em;
    top: 59px;
    left: 10px;

    width: 183px;
    height: 26px;
}
.profsdata-languages{
    font-size: .8em;
    top: 100px;
    left: 10px;

    width: 183px;
    height: 25.5px;
}
.profsdata-senses{
    font-size: .8em;
    top: 141px;
    left: 10px;

    width: 181px;
    height: 25.5px;
} 